import Cookies, { CookieSetOptions } from 'universal-cookie';
import { env } from '@/env';

class JucyCookies extends Cookies {
    set(name: string, value: unknown, options?: CookieSetOptions): void {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + 86400 * 395);
        super.set(name, value, {
            domain: env.NEXT_PUBLIC_ENV === 'dev' ? 'localhost' : '.jucy.com',
            expires: expires,
            path: '/',
            secure: env.NEXT_PUBLIC_ENV !== 'dev',
            httpOnly: false,
            sameSite: false,
            ...options,
        });
    }
}

export const cookies = new JucyCookies();
